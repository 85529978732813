/** @jsx h */
import { h, Fragment } from "preact";
import { useContext, useEffect, useMemo, useState } from "preact/hooks";
import { renderPreact } from "../lib/render-preact";
import PageContext from "./PageContext";
import { translate, t } from "restaumatic-client";
import Alert from "./Alert";
import { formatRestaurantAddress } from "../utils/formatAddress";
import { formatOpeningHours } from "../utils/formatOpeningHours";
import { formatAddressAsSingleLine, formatFulfillmentMethodType, GeocodingService, LocalizationProvider, Money, PGeolocation, RestaumaticRPCService, StreetAddress, WebGeolocationService, StreetAddressFormController, DeliveryAddressStatus, StorageManagerFactory, LocalStorage, } from "restaumatic-client/src";
import { observer } from "mobx-react";
import StreetAddressForm from "./StreetAddressForm";
import LocateMeButton from "./LocateMeButton";
import { Heading } from "./Heading";
import useEventEffect from "../utils/useEventEffect";
import useScrollIfAboveEffect from "../utils/useScrollIfAboveEffect";
import PreactCollapse from "./PreactCollapse";
const restaurantSelectionId = "restaurant-selection";
// Focus on top of the restaurant selection module
const focusRestaurantSelection = () => {
    const restaurantSelectionEl = document.getElementById(restaurantSelectionId);
    if (restaurantSelectionEl) {
        restaurantSelectionEl.focus({ preventScroll: true });
    }
};
// Sort the list based on the 'isSelected' property (true value come first)
const sortRestaurants = (list) => {
    return list.sort((a, b) => {
        if (a.isSelected === b.isSelected) {
            return 0;
        }
        return a.isSelected ? -1 : 1;
    });
};
// Mark current restaurant as selected.
const updateRestaurantsWithLatest = (restaurants) => {
    if (typeof window === "undefined")
        return restaurants;
    const currentRestaurantSlug = window.localStorage.getItem("currentRestaurant");
    if (!currentRestaurantSlug)
        return restaurants;
    restaurants = restaurants.map((restaurant) => ({
        ...restaurant,
        isSelected: restaurant.slug === currentRestaurantSlug,
    }));
    return sortRestaurants(restaurants);
};
function toCardProps(localizationProvider, r) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const deliveryCost = (_b = (_a = r.deliveryInfo) === null || _a === void 0 ? void 0 : _a.deliveryCost) !== null && _b !== void 0 ? _b : null;
    const minOrderValue = (_d = (_c = r.deliveryInfo) === null || _c === void 0 ? void 0 : _c.minOrderValue) !== null && _d !== void 0 ? _d : null;
    const freeThreshold = (_f = (_e = r.deliveryInfo) === null || _e === void 0 ? void 0 : _e.freeDeliveryThreshold) !== null && _f !== void 0 ? _f : null;
    return {
        id: r.id,
        name: r.name,
        slug: (_g = r.slug) !== null && _g !== void 0 ? _g : "",
        address: formatRestaurantAddress(r.address),
        phones: r.contactPhoneNumber
            ? r.contactPhoneNumber.split(",").map((s) => s.trim())
            : [],
        email: (_h = r.contactEmail) !== null && _h !== void 0 ? _h : "",
        deliveryTypes: r.fulfillmentMethods
            .map(formatFulfillmentMethodType)
            .join(", "),
        menuLink: `/restauracja/${r.slug}`,
        isWithinArea: !!r.deliveryInfo,
        isSelected: false,
        openingHours: formatOpeningHours(r.restaurantHours),
        deliveryCost: deliveryCost !== null
            ? localizationProvider.formatPrice(Money.fromBackend(deliveryCost))
            : null,
        minOrderValue: minOrderValue !== null
            ? localizationProvider.formatPrice(Money.fromBackend(minOrderValue))
            : null,
        freeTreshold: freeThreshold !== null
            ? localizationProvider.formatPrice(Money.fromBackend(freeThreshold))
            : null,
    };
}
function RestaurantSelection(props) {
    const settings = useContext(PageContext);
    const localizationProvider = new LocalizationProvider({
        locale: settings.locale,
        fallbackLocale: settings.defaultLocale,
        currency: settings.currency,
    });
    // TODO: move to "initialize method" with memo
    const rpcService = new RestaumaticRPCService({ url: "" });
    const geocodingService = new GeocodingService({
        rpcService: rpcService,
        siteId: settings.siteId,
    });
    const storageManagerFactory = new StorageManagerFactory({
        storage: LocalStorage.create(),
    });
    const streetAddressStorageManager = storageManagerFactory.forStreetAddress();
    const apartmentInfoStorageManager = storageManagerFactory.forApartmentInfo();
    const { titleExtraClass = "", cardTitleExtraClass = "", cardExtraClass = "", listColumnNumber = 3, listHeadingLevel = "h3", cardHeadingLevel = "h4", onSelect, } = props;
    // TODO: move logic to separate mobx class with computed values
    const [rootKey, setRootKey] = useState(0);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const [streetAddress, setStreetAddress] = useState(() => StreetAddress.empty(settings.country));
    const [submittedAddress, setSubmittedAddress] = useState(null);
    const [status, setStatus] = useState(DeliveryAddressStatus.NotRequested);
    const loading = status.state.type === "Loading";
    const backendRestaurants = status.state.type === "ChooseRestaurant"
        ? status.state.restaurants
        : props.initialRestaurants;
    const restaurants = updateRestaurantsWithLatest(backendRestaurants.map((r) => toCardProps(localizationProvider, r)));
    const deliveringRestaurants = restaurants.filter((item) => item.isWithinArea);
    const otherRestaurants = restaurants.filter((item) => !item.isWithinArea);
    const showDeliveringRestaurants = deliveringRestaurants.length > 0;
    // Add event listeners for changing selected restaurants with `select_restaurant.liquid`
    // Re-render component to update restaurants with new `isSelected` value
    useEventEffect("change-restaurant.restaumatic", () => {
        setRootKey((prevRootKey) => prevRootKey + 1);
    });
    // Scrolling to the top of restaurant selection module
    useScrollIfAboveEffect(scrolling, restaurantSelectionId, () => {
        setScrolling(false);
    });
    // Makes the form visible or not, and scrolling
    useEffect(() => {
        if (status.state.type === "ChooseRestaurant") {
            const anyDelivers = backendRestaurants.some((item) => item.deliveryInfo);
            if (anyDelivers) {
                setIsFormVisible(false);
                setScrolling(true);
            }
            else {
                setIsFormVisible(true);
                focusRestaurantSelection();
            }
        }
    }, [status.state.type, backendRestaurants]);
    // Restore from storage
    useEffect(() => {
        let restoredStreetAddress = StreetAddress.fromStorageData({
            country: settings.country,
            storageData: streetAddressStorageManager.restore(),
        });
        if (!restoredStreetAddress.isEmpty) {
            setStreetAddress(restoredStreetAddress);
            onSubmit(restoredStreetAddress);
        }
    }, []);
    const onEdit = () => {
        setIsFormVisible(true);
        setScrolling(true);
        focusRestaurantSelection();
    };
    const onSubmit = (streetAddress) => {
        // Get new status
        setStatus(DeliveryAddressStatus.create({
            restaurantId: null,
            streetAddress,
            geocodingService,
        }));
        // Clear apartment info if street address has been changed
        if (submittedAddress !== null && !streetAddress.eq(submittedAddress)) {
            apartmentInfoStorageManager.erase();
        }
        // Store new value in storage
        streetAddressStorageManager.store(streetAddress.storageData);
        // Update submitted address
        setSubmittedAddress(streetAddress.clone());
    };
    return (h("div", { id: restaurantSelectionId, key: rootKey, className: "m-restaurants", tabIndex: -1, "aria-live": "polite" },
        isFormVisible && (h("article", { className: "m-restaurants__content" },
            h("div", { className: "m-restaurants__form-wrapper" },
                h(AddressForm, { titleExtraClass: titleExtraClass, listHeadingLevel: listHeadingLevel, streetAddress: streetAddress, loading: loading, onSubmit: () => onSubmit(streetAddress) }),
                h(AddressStatus, { status: status })))),
        showDeliveringRestaurants ? (h(Fragment, null,
            h(RestaurantList, { title: translate(t.restaurants.select.header_deliver), list: deliveringRestaurants, titleExtraClass: titleExtraClass, cardTitleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, listHeadingLevel: listHeadingLevel, cardHeadingLevel: cardHeadingLevel, doesRestaurantDeliver: true, onSelect: onSelect, onEdit: onEdit, address: submittedAddress }),
            h(RestaurantList, { title: translate(t.restaurants.select.header_others), list: otherRestaurants, titleExtraClass: titleExtraClass, cardTitleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, listHeadingLevel: listHeadingLevel, cardHeadingLevel: cardHeadingLevel, doesRestaurantDeliver: false, onSelect: onSelect, onEdit: onEdit, address: submittedAddress }))) : (h(RestaurantList, { title: translate(t.restaurants.select.header_all), showAddressInHeader: false, list: restaurants, titleExtraClass: titleExtraClass, cardTitleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, listHeadingLevel: listHeadingLevel, cardHeadingLevel: cardHeadingLevel, address: submittedAddress, onSelect: onSelect }))));
}
function AddressStatus(props) {
    const { status } = props;
    switch (status.state.type) {
        case "NetworkError":
            return (h(Alert, { type: "danger", message: translate(t.errors.messages.network) }));
        case "AddressDoesNotExistOrInaccurate":
            return (h(Alert, { type: "danger", message: translate(t.delivery.geocoding_error) }));
        case "RestaurantDoesntDeliverTo": {
            const address = status.state.streetAddress;
            const message = `${translate(t.restaurants.select.error_address)} ${formatAddressAsSingleLine(address, address.isPostCodeRequired)}`;
            return h(Alert, { type: "danger", message: message });
        }
        default:
            return null;
    }
}
const AddressForm = observer((props) => {
    const { titleExtraClass, listHeadingLevel, streetAddress, loading, onSubmit, } = props;
    const settings = useContext(PageContext);
    const geolocation = useMemo(() => {
        return new PGeolocation({
            restaurantId: null,
            geolocationService: new WebGeolocationService(),
            geocodingService: new GeocodingService({
                rpcService: new RestaumaticRPCService({ url: "" }),
                siteId: settings.siteId,
            }),
        });
    }, [settings.siteId]);
    const geolocationLoading = geolocation.status.type === "Loading";
    // Note: controller is reinitialized whenever we get a new StreetAddress object,
    // which happens e.g. when loading from storage
    const controller = useMemo(() => {
        return new StreetAddressFormController({ streetAddress });
    }, [streetAddress]);
    const handleLocateMe = () => {
        geolocation.locateMe().then(() => {
            if (geolocation.status.type === "Success") {
                const { city, street, streetNumber } = controller.fields;
                const { streetAddress } = geolocation.status;
                city.change(streetAddress.city);
                street.change(streetAddress.street);
                streetNumber.change(streetAddress.streetNumber);
                onSubmit();
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };
    return (h(Fragment, null,
        h(Heading, { level: listHeadingLevel, className: `m-restaurants__heading ${titleExtraClass}`, id: "restaurant-selection-form-heading" }, translate(t.restaurants.select.title_find)),
        h("form", { "aria-controls": "restaurant-selection-list", "aria-labelledby": "restaurant-selection-form-heading", onSubmit: handleSubmit },
            h(LocateMeButton, { onLocateMe: handleLocateMe, isLoading: geolocationLoading, geolocationError: geolocation.error }),
            h("div", { className: "m-delivery-address__container" },
                h(StreetAddressForm, { fieldId: "fulfillmentMethod.deliveryAddress", controller: controller })),
            h("button", { type: "submit", className: "btn btn-primary", disabled: loading }, translate(t.restaurants.select.find_restaurant)))));
});
function RestaurantList(props) {
    const { title, list, titleExtraClass, cardTitleExtraClass, cardExtraClass, listColumnNumber, listHeadingLevel, cardHeadingLevel, doesRestaurantDeliver, onSelect, onEdit, address, } = props;
    if (list.length === 0) {
        return h(Fragment, null);
    }
    return (h("article", { className: "m-restaurants__content" },
        h(Heading, { level: listHeadingLevel, className: `m-restaurants__heading ${titleExtraClass}`, id: "restaurant-selection-list-heading" },
            h("span", null,
                title,
                " "),
            address &&
                doesRestaurantDeliver !== false &&
                props.showAddressInHeader !== false && (h(Fragment, null,
                h("span", null,
                    formatAddressAsSingleLine(address, address.isPostCodeRequired),
                    "\u00A0"),
                h("button", { type: "button", className: "btn btn-link u-p0 u-box-shadow-none", onClick: onEdit }, "(" + translate(t.restaurants.select.button_change) + ")")))),
        h("ul", { className: "list-unstyled row row--flex row--vertical-gutter", id: "restaurant-selection-list", "aria-labelledby": "restaurant-selection-list-heading" }, list.map((item) => (h(RestaurantCard, { restaurant: item, titleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, cardHeadingLevel: cardHeadingLevel, doesRestaurantDeliver: doesRestaurantDeliver, onSelect: onSelect, onEdit: onEdit, address: address }))))));
}
function RestaurantCard(props) {
    const { restaurant, doesRestaurantDeliver, titleExtraClass, cardExtraClass, listColumnNumber, cardHeadingLevel, onSelect, onEdit, address, } = props;
    const openingHours = restaurant.openingHours.split("\n");
    const columnGridWidth = 12 / listColumnNumber; // 12 is number of columns specified by Bootstrap Grid System
    const freeTresholdString = restaurant.freeTreshold
        ? ", " +
            translate(t.theme_defaults.delivery.info.free_treshold) +
            restaurant.freeTreshold
        : "";
    const deliveryCostDetailId = `delivery-cost-details-${restaurant.id}`;
    return (h("li", { className: `col-sm-6 col-md-${columnGridWidth}` },
        h("div", { class: `m-restaurants__card ${cardExtraClass} ${restaurant.isSelected ? "m-restaurants__card--selected" : ""}` },
            h(Heading, { level: cardHeadingLevel, className: `m-restaurants__card-title ${titleExtraClass}` }, restaurant.name),
            h("ul", { className: "u-list-unstyled u-flex-grow" },
                h("li", { className: "d-flex align-items-baseline u-my3" }, restaurant.address),
                h("li", { className: "d-flex align-items-baseline u-my3" },
                    h("span", { className: "m-restaurants__card-icon icon-time icon-base", "aria-hidden": "true" }),
                    h("div", null, openingHours.map((line) => (h(Fragment, null,
                        line,
                        h("br", null)))))),
                restaurant.phones.length > 0 && (h("li", { className: "d-flex align-items-baseline u-my3" },
                    h("span", { className: "m-restaurants__card-icon icon-phone icon-base", "aria-hidden": "true" }),
                    h("div", null, restaurant.phones.map((item) => (h("a", { href: `tel:${item}`, className: "u-block u-link-unstyled" }, item)))))),
                restaurant.email && (h("li", { className: "d-flex align-items-baseline u-my3" },
                    h("span", { className: "m-restaurants__card-icon icon-mail icon-base", "aria-hidden": "true" }),
                    h("a", { href: `mailto:${restaurant.email}`, className: "u-link-unstyled u-text-ellipsis" }, restaurant.email))),
                h("li", { className: "d-flex align-items-baseline u-my3" },
                    h("span", { className: "m-restaurants__card-icon icon-shopping-cart icon-base", "aria-hidden": "true" }),
                    h("span", null, restaurant.deliveryTypes)),
                doesRestaurantDeliver === true && (h("li", { className: "d-flex align-items-baseline u-my3" },
                    h("span", { className: "m-restaurants__card-icon icon-truck icon-base", "aria-hidden": "true" }),
                    h("span", null,
                        h("span", { className: "d-flex" },
                            h("span", null,
                                restaurant.deliveryCost,
                                " (min. ",
                                restaurant.minOrderValue,
                                ")"),
                            h(PreactCollapse, { class: "m-restaurants__btn-collapse", target: `#${deliveryCostDetailId}` })),
                        h("div", { id: deliveryCostDetailId, className: "collapse" },
                            h("div", null, `${translate(t.theme_defaults.delivery.info.cost)}${restaurant.deliveryCost},`),
                            h("div", null, `${translate(t.theme_defaults.delivery.info.min_order_value)}${restaurant.minOrderValue}${freeTresholdString}`)))))),
            address && doesRestaurantDeliver === false && (h(NoWithinAreaAlert, { onEdit: onEdit, address: address })),
            h("div", null,
                h("button", { className: "m-restaurants__card-action btn btn-primary", onClick: () => onSelect(restaurant) }, translate(t.restaurants.select.button_select))))));
}
function NoWithinAreaAlert(props) {
    const { onEdit, address } = props;
    return (h(Fragment, null,
        h(Alert, { type: "warning", extraClasses: "u-my2", message: translate(t.delivery.not_possible, {
                address: formatAddressAsSingleLine(address, address.isPostCodeRequired),
            }), link: {
                label: `(${translate(t.restaurants.select.button_change)})`,
                path: "#" + restaurantSelectionId,
                fieldId: "change-address-link",
                onClick: onEdit
                    ? (e) => {
                        e.preventDefault();
                        onEdit();
                    }
                    : undefined,
            } })));
}
const ObservedRestaurantSelection = observer(RestaurantSelection);
export const RestaurantSelectionSSRComponent = renderPreact("RestaurantSelection", (props) => (h(ObservedRestaurantSelection, { onSelect: (restaurant) => window.location.assign(restaurant.menuLink), ...props })));
export default ObservedRestaurantSelection;
